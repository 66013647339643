@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  body {
    @apply bg-background text-white;
  }
}

@layer components {
  .container {
    @apply px-4 mx-auto max-w-7xl sm:px-6 lg:px-8;
  }
  .btn-primary {
    @apply px-8 py-3 font-display text-xl font-bold text-white transition-all duration-300 rounded-xl bg-blue-300/90 hover:bg-yellow-400 hover:scale-105 hover:shadow-xl shadow-lg backdrop-blur-sm;
  }
  .btn-secondary {
    @apply px-8 py-3 font-display text-xl font-bold transition-all duration-300 rounded-xl bg-background-secondary/50 hover:bg-blue-300/90 text-yellow-400 hover:text-white hover:scale-105 hover:shadow-xl shadow-lg backdrop-blur-sm border-2 border-yellow-400/50 hover:border-blue-300/50;
  }
}

@keyframes scroll {
  0% {
    transform: translateX(0);
  }
  100% {
    transform: translateX(-50%);
  }
}

.animate-scroll {
  animation: scroll 30s linear infinite;
}